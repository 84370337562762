import React from "react";
import {
  NotificationOutlined,
  AccountBookFilled,
  BellFilled,
  LinkOutlined,
  NotificationFilled,
  SettingFilled,
  FormOutlined,
  TableOutlined,
  PhoneOutlined,
  WalletOutlined,
  ContactsOutlined,
  ShopOutlined,
  DeploymentUnitOutlined,
  HistoryOutlined,
  GiftOutlined,
  DashboardOutlined
} from "@ant-design/icons";
import { BsJournalBookmark } from "react-icons/bs";
import { PiTicket } from "react-icons/pi";
import { useEnvironment } from "@/providers/app/EnvironmentProvider";

export const AppRoutes = {
  companies: "/dashboard", // List all companies
  login: "/dashboard/login",
  signup: "/dashboard/signup",
  reset_password: "/reset-password",
  company_creation: "/dashboard/company_creation",
  registration_quetion: {
    detail_step: (step: string) =>
      `/dashboard/registration_quetion?step=${step}`,
  },
};

export const AppCompanyRoutes = (COMPANY_ID?: string) => {
  return {
    dashboard: `/dashboard/${COMPANY_ID}/contacts/contact/0`,
    notifications: `/dashboard/${COMPANY_ID}/notifications`,
    contacts: {
      list: `/dashboard/${COMPANY_ID}/contacts/list`,
      contact: `/dashboard/${COMPANY_ID}/contacts/contact`,
      enterprise: `/dashboard/${COMPANY_ID}/contacts/enterprise`,
      detail: (CONTACT_ID: string) =>
        `/dashboard/${COMPANY_ID}/contacts/detail/${CONTACT_ID}`,
      enterprise_detail: (ID: string) =>
        `/dashboard/${COMPANY_ID}/contacts/detail_enterprise/${ID}`,
    },
    chat: {
      detail: (CHANNEL_ID: string, ACCOUNT_ID: string, THREAD_ID: string) =>
        `/dashboard/${COMPANY_ID}/chat/${CHANNEL_ID}/${ACCOUNT_ID}/${THREAD_ID}`,
      channel: (CHANNEL_ID: string) =>
        `/dashboard/${COMPANY_ID}/chat/${CHANNEL_ID}`,
      facebook_page: (ACCOUNT_ID: string) =>
        `/dashboard/${COMPANY_ID}/chat/facebook/${ACCOUNT_ID}`,
      zalo_oa: (ACCOUNT_ID: string) =>
        `/dashboard/${COMPANY_ID}/chat/zalo_oa/${ACCOUNT_ID}`,
      zalo: (ACCOUNT_ID: string) =>
        `/dashboard/${COMPANY_ID}/chat/zalo/${ACCOUNT_ID}`,
    },
    campaigns: {
      messages: {
        list: `/dashboard/${COMPANY_ID}/campaigns/messages`,
        detail: (SMS_CAMPAIGN_ID: string) =>
          `/dashboard/${COMPANY_ID}/campaigns/messages/${SMS_CAMPAIGN_ID}/detail`,
        report: (SMS_CAMPAIGN_ID: string) =>
          `/dashboard/${COMPANY_ID}/campaigns/messages/${SMS_CAMPAIGN_ID}/report`,
        detail_step: (SMS_CAMPAIGN_ID: string, step: string, hash?: string) =>
          `/dashboard/${COMPANY_ID}/campaigns/messages/${SMS_CAMPAIGN_ID}/detail?step=${step}${hash ? `#${hash}` : ""}`,
      },
      telesales: {
        list: `/dashboard/${COMPANY_ID}/campaigns/telesales`,
        deleted: `/dashboard/${COMPANY_ID}/campaigns/telesales/deleted_list`,
        detail_step: (TELESALES_ID: string, step: string, hash?: string) =>
          `/dashboard/${COMPANY_ID}/campaigns/telesales/${TELESALES_ID}/detail?step=${step}${hash ? `#${hash}` : ""}`,
        report: (TELESALES_ID: string) =>
          `/dashboard/${COMPANY_ID}/campaigns/telesales/${TELESALES_ID}/report`,
        call_telesales: (TELESALES_ID: string) =>
          `/dashboard/${COMPANY_ID}/campaigns/telesales/${TELESALES_ID}/call_telesales`,
      },
    },
    settings: {
      general: {
        list: `/dashboard/${COMPANY_ID}/settings/general`,
        detail: (SETTING_ID: string) =>
          `/dashboard/${COMPANY_ID}/settings/general/${SETTING_ID}`,
      },
      config_data: {
        detail_property: (
          SETTING_ID: string,
          PROPERTY_ID: string,
          PROPERTY_NAME: string
        ) =>
          `/dashboard/${COMPANY_ID}/settings/general/${SETTING_ID}?property_id=${PROPERTY_ID}&property_name=${PROPERTY_NAME}`,
      },
    },
    script: {
      sample_template: {
        list: `/dashboard/${COMPANY_ID}/script/sample_template`,
      },
      email_template: {
        list: `/dashboard/${COMPANY_ID}/script/email_template`,
      },
    },
    help: `/dashboard/${COMPANY_ID}/help`,
    wallet: {
      deposit: `/dashboard/${COMPANY_ID}/wallet/deposit`,
    },
    sales: {
      order: `/dashboard/${COMPANY_ID}/sales/order`,
      order_details: (ORDER_ID: string) =>
        `/dashboard/${COMPANY_ID}/sales/order/details/${ORDER_ID}`,
      deal: `/dashboard/${COMPANY_ID}/sales/deal`,
      deal_details: (DEAL_ID: string) =>
        `/dashboard/${COMPANY_ID}/sales/deal/detail/${DEAL_ID}`,
    },
    dashboard_kpi: {
      kpi: {
        list: `/dashboard/${COMPANY_ID}/dashboard_kpi/kpi`,
      },
    },
  };
};

export const AppMenuRoutes = (COMPANY_ID?: string, t: any) => {
  const { FEATURE_FLAGS } = useEnvironment();
  return {
    path: "/",
    routes: [
      {
        path: `/dashboard/${COMPANY_ID}`,
        name: "",
        routes: [
          {
            path: "contacts",
            name: t("contacts.menu"),
            icon: <ContactsOutlined style={{ fontSize: 20 }} />,
            routes: [
              {
                path: "contact",
                permission_code: "contact",
                name: t("contacts.title"),
              },
              {
                path: "enterprise",
                name: t("contacts.customer_information.company"),
              },
              {
                path: "list",
                name: t("contacts.list.menu"),
              },
              {
                path: "ulead",
                name: t("contacts.ulead.menu"),
              },
            ],
          },
          {
            path: "sales",
            name: t("sales.menu"),
            icon: <ShopOutlined style={{ fontSize: 20 }} />,
            routes: [
              // {
              //   path: "opportunity",
              //   name: t("sales.opportunity.menu"),
              // },
              {
                path: "order",
                name: t("sales.order.menu"),
              },
              {
                path: "products",
                name: t("sales.products"),
                children: [
                  {
                    path: ":PRODUCT_ID/detail",
                    hideInMenu: true,
                    name: t("sales.crmproduct.detail_product"),
                  },
                ],
              },
              {
                path: "deal",
                name: t("sales.deal.menu"),
              },
            ],
          },
          {
            path: "script",
            name: t("script.menu"),
            icon: <BsJournalBookmark style={{ fontSize: 20 }} />,
            routes: [
              {
                path: "sample_template",
                name: t("script.sample_template"),
                reload: true,
              },
              {
                path: "callbot_template",
                name: t("script.callbot_template.menu"),
              },
              {
                path: "email_template",
                name: t("script.email_template.menu"),
              },
            ],
          },
          {
            path: "campaigns",
            name: t("campaigns.menu"),
            icon: <NotificationOutlined style={{ fontSize: 20 }} />,
            routes: [
              {
                path: "call",
                name: t("campaigns.call.title"),
                reload: true,
                children: [],
              },
              {
                path: "telesales",
                name: t("campaigns.telesales.title"),
                reload: true,
                children: [],
              },
              {
                path: "dials",
                name: t("campaigns.dials.title"),
                reload: true,
                children: [],
              },
              {
                path: "progressive",
                name: t("campaigns.progressive.title"),
                reload: true,
                children: [],
              },
              FEATURE_FLAGS.OMNI_CHANNEL_CHAT && {
                path: "messages",
                name: t("campaigns.messages.title"),
                children: [
                  {
                    path: ":SMS_CAMPAIGN_ID/detail",
                    hideInMenu: true,
                    name: t("campaigns.messages.detail"),
                  },
                  {
                    path: ":SMS_CAMPAIGN_ID/report",
                    hideInMenu: true,
                    name: t("campaigns.messages.report"),
                  },
                ],
              },
            ],
          },
          FEATURE_FLAGS.OMNI_CHANNEL_CHAT && {
            path: "chat",
            name: t("chat.menu"),
            icon: <DeploymentUnitOutlined style={{ fontSize: 20 }} />,
            routes: [
              {
                path: "",
                name: t("chat.list"),
              },
              {
                path: "inbox/:chatId",
                name: t("chat.detail.menu"),
                hideInMenu: true,
              },
            ],
          },
          // {
          //   path: "record_sheet",
          //   name: t("record_sheet.menu"),
          //   icon: <PiTicket />,
          // },
          {
            path: "history",
            name: t("history.menu"),
            icon: <HistoryOutlined style={{ fontSize: 20 }} />,
            routes: [
              {
                path: "calls",
                reload: true,
                name: t("calls.list"),
              },
            ],
          },
          {
            path: "wallet",
            name: t("wallet.menu"),
            icon: <WalletOutlined style={{ fontSize: 20 }} />,
            routes: [
              {
                path: "deposit",
                name: t("wallet.deposit"),
              },
              {
                path: "expenses",
                name: t("wallet.expenses"),
              },
              {
                path: "purchases",
                name: t("wallet.purchases"),
              },
            ],
          },
          {
            path: "affiliate",
            name: t("affiliate.menu"),
            icon: <GiftOutlined style={{ fontSize: 20 }} />,
            routes: [
              {
                path: "invite",
                name: t("affiliate.invite.menu"),
              },
              {
                path: "customer",
                name: t("affiliate.customer.menu"),
              },
            ],
          },
          {
            path: "dashboard_kpi",
            name: t("dashboard_kpi.menu"),
            icon: <DashboardOutlined style={{ fontSize: 20 }} />,
            routes: [
              {
                path: "kpi",
                reload: true,
                name: "KPI",
              },
            ],
          }
        ],
      },
    ],
  };
};
